<template>
  <v-app :class="{ landing: $route.name === 'landing', 'site-background': $route.name !== 'landing'  }">
    <v-container fluid>
      <v-main>
        <router-view />
      </v-main>
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
};
</script>

