<template>
  <v-card class="px-9 py-5 org-profile-edit" color="#EEF8FF">
    <div class="d-flex">
      <div
        style="color: #020659"
        class="d-flex align-center cursor-pointer"
        @click="$emit('closed')"
      >
        <v-icon color="#020659" class="mr-2"
          >mdi-arrow-left-thin-circle-outline</v-icon
        >
      </div>
    </div>

    <v-card class="mx-auto my-5" tile elevation="1">
      <v-card
        color="#020659"
        class="white--text d-flex align-center px-11"
        height="70"
      ><v-text-field style="width:30%; margin: 20px 0px 0px 0px;" v-model="profile.name" dark label="Organization Name" readonly></v-text-field></v-card>
      <v-row class="pb-2 pt-7 px-11">
        <v-col cols="2">
          <v-img
            width="300"
            height="277"
            :src="profilePictureUrl ? profilePictureUrl : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'"
          ></v-img>
        </v-col>
        <v-col cols="10">
          <v-row style="border-bottom: 4px solid #dbf0ff;">
            <v-col cols="4" style="border-right: 4px solid #dbf0ff">
              <v-row no-gutters class="mt-5">
                <v-col cols="12">
                  <v-text-field label="Contact Name & Title" dense v-model="profile.contactNameAndTitle" readonly></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters class="mt-5">
                <v-col cols="12">
                  <v-text-field dense label="E-Mail" v-model="profile.contactEmail" readonly></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters class="mt-5">
                <v-col cols="12">
                  <v-text-field label="Website/LinkedIn" dense v-model="profile.website"></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters class="mt-5">
                <v-col cols="12">
                  <v-autocomplete
                    multiple
                    dense
                    label="Counties Served"
                    v-model="profile.countiesServed"
                    :items="counties"
                  >
                    <template v-slot:selection="{ attrs, item, parent, selected }">
                      <v-chip
                        v-bind="attrs"
                        :input-value="selected"
                        label
                        small
                        class="ma-1"
                      >
                        <span class="pr-2">
                          {{ item.name || item }}
                        </span>
                        <v-icon
                          small
                          @click="parent.selectItem(item)"
                        >
                          $delete
                        </v-icon>
                      </v-chip>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="4"
              class="px-9"
              style="border-right: 4px solid #dbf0ff"
            >
              <v-autocomplete
                label="Designations"
                multiple
                v-model="profile.demographics"
                :items="demographics"
              >
                <template v-slot:selection="{ attrs, item, parent, selected }">
                  <v-chip
                    v-bind="attrs"
                    :input-value="selected"
                    label
                    small
                  >
                    <span class="pr-2">
                      {{ item.name || item }}
                    </span>
                    <v-icon
                      small
                      @click="parent.selectItem(item)"
                    >
                      $delete
                    </v-icon>
                  </v-chip>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="4" class="px-9">
              <v-autocomplete
                label="Area of Focus"
                multiple
                v-model="profile.areasOfFocus"
                :items="areasOfFocuses"
              >
                <template v-slot:selection="{ attrs, item, parent, selected }">
                  <v-chip
                    v-bind="attrs"
                    :input-value="selected"
                    label
                    small
                  >
                    <span class="pr-2">
                      {{ item.name || item }}
                    </span>
                    <v-icon
                      small
                      @click="parent.selectItem(item)"
                    >
                      $delete
                    </v-icon>
                  </v-chip>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="px-11 mt-3">
        <v-col cols="3" class="d-flex align-center">
          <v-file-input @change="profilePictureUpdated" label="Upload new photo" v-model="profile.profilePicture"></v-file-input>
        </v-col>
        <v-col cols="3" class="d-flex align-center">
          <v-select
            label="Organization Type"
            v-model="profile.organizationTypes"
            multiple
            :items="organizationTypes"
          >
          </v-select>
        </v-col>
        <v-col cols="3" class="d-flex align-center">
          <v-checkbox label="Accepting New Clients" v-model="profile.acceptingNewClients"></v-checkbox>
        </v-col>
        <v-col cols="3" class="d-flex align-center">
          <v-checkbox label="Visible In ImpactHQ" v-model="profile.visibleInImpactHQ"></v-checkbox>
        </v-col>
      </v-row>
    </v-card>
    <v-row>
      <v-col cols="6">
        <v-card color="#020659" class="white--text px-11 py-4" height="300" style="overflow:auto">
          <div class="d-flex align-center justify-space-between">
            <h2>Services Provided</h2>
          </div>
          <v-divider style="border-color: white"></v-divider>
          <v-row>
            <v-col cols="12" class="pr-6">
              <v-autocomplete
                dark
                multiple
                :items="serviceTypes"
                v-model="profile.services"
              >
                <template v-slot:selection="{ attrs, item, parent, selected }">
                  <v-chip
                    v-bind="attrs"
                    :input-value="selected"
                    label
                    small
                  >
                    <span class="pr-2">
                      {{ item.name || item }}
                    </span>
                    <v-icon
                      small
                      @click="parent.selectItem(item)"
                    >
                      $delete
                    </v-icon>
                  </v-chip>
                </template>
              </v-autocomplete>
              <v-text-field 
                v-if="profile.services?.includes('Other (please specify)')" 
                v-model="profile.otherServices" 
                label="Other Services" 
                dark
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card class="px-11 py-4" height="300" style="overflow-y: auto">
          <div class="d-flex align-center justify-space-between">
            <h2 class="themepurple">About the Organization</h2>
          </div>
          <v-divider style="border-color: #020659"></v-divider>
          <div>
            <v-textarea
              v-model="profile.description"
            ></v-textarea>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="5">
        <v-card color="#C5D5EA" class="px-11 py-4">
          <div class="d-flex mb-2 align-center">
            <v-icon class="themepurple"> mdi-check-circle</v-icon>
            <div class="ml-3 themepurple"><b>PROGRAMS: (Input any specific programs, trainings or workshops you offer)</b></div>
          </div>
          <v-row>
            <v-col cols="12">
              <v-combobox
                multiple
                v-model="profile.programs"
                chips
              >
                <template v-slot:selection="{ attrs, item, parent, selected }">
                  <v-chip
                    v-bind="attrs"
                    :input-value="selected"
                    label
                    small
                  >
                    <span class="pr-2">
                      {{ item }}
                    </span>
                    <v-icon
                      small
                      @click="parent.selectItem(item)"
                    >
                      $delete
                    </v-icon>
                  </v-chip>
                </template>
              </v-combobox>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="7">
        <v-card color="#C5D5EA" class="px-11 py-4">
          <div class="d-flex mb-2 align-center">
            <v-icon class="themepurple"> mdi-shield-check</v-icon>
            <div class="ml-3 themepurple">
              <b>CERTIFICATIONS/LICENSES:</b>
            </div>
          </div>
          <v-row>
            <v-col cols="12">
              <v-combobox
                multiple
                v-model="profile.certifications"
                chips
              >
                <template v-slot:selection="{ attrs, item, parent, selected }">
                  <v-chip
                    v-bind="attrs"
                    :input-value="selected"
                    label
                    small
                  >
                    <span class="pr-2">
                      {{ item }}
                    </span>
                    <v-icon
                      small
                      @click="parent.selectItem(item)"
                    >
                      $delete
                    </v-icon>
                  </v-chip>
                </template>
              </v-combobox>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="text-right">
        <v-btn
          color="#020659"
          class="white--text"
          @click="$emit('closed')"
          :disabled="sending"
        >Cancel</v-btn>
        <v-btn
          color="#020659"
          class="white--text ml-3"
          @click="save"
          :loading="sending"
        >Save</v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<style>
.org-profile-edit .v-label.theme--light {
  font-weight: bold;
  color: #020659 !important;
}

.v-card > *:first-child:not(.v-btn):not(.v-chip):not(.v-avatar) {
  border-radius: 0px;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
 box-shadow: none; 
}

.v-sheet.v-card {
  border-radius: 0px;
}

</style>

<script>
import Vue from "vue";

export default {
  name: "OrganizationProfileEdit",
  data() {
    return {
      sending: false,
      profilePictureUrl: null,
      profile: {
        profilePicture: null,
        name: null,
        contactNameAndTitle: null,
        contactEmail: null,
        website: null,
        countiesServed: [],
        demographics: [],
        areasOfFocus: [],
        programs: [],
        certifications: [],
        services: [],
        organizationTypes: [],
        nonprofitOrganization: false,
        acceptingNewClients: false,
        description: null,
        visibleInImpactHQ: true
      },
      counties: [
        'Niagara', 'Erie', 'Genesee',
        'Monroe', 'Wyoming', 'Allegany',
        'Cattaraugus', 'Chautauqua', 'Orleans'
      ],
      demographics: [],
      areasOfFocuses: [],
      serviceTypes: [],
      organizationTypes: [
        'Consultant', 'Vendor', 'Service Professional', 'Coach'
      ]
    };
  },
  async created() {
    var data = await Promise.all([
      Vue.$organizationService.currentOrganization(),
      Vue.$choiceService.find('demographic-choices'),
      Vue.$choiceService.find('area-of-focus-choices'),
      Vue.$choiceService.find('service-choices'),
    ])
    this.profile = data[0].data;
    this.demographics = data[1].data.data.map(d => d.attributes.name).sort();
    this.areasOfFocuses = data[2].data.data.map(d => d.attributes.name).sort();
    this.serviceTypes = data[3].data.data.map(d => d.attributes.name).sort();
    if (JSON.stringify(this.profile) === '{}') {
      var currentUser = await Vue.$userService.current();
      console.log(currentUser);
      this.profile =  {
        profilePicture: null,
        name: currentUser.allcoUser.organization.name,
        contactNameAndTitle: `${currentUser.allcoUser.contact.firstName} ${currentUser.allcoUser.contact.lastName}${(currentUser.allcoUser.contact.title ? ', ' + currentUser.allcoUser.contact.title : '')}`,
        contactEmail: currentUser.allcoUser.contact.email,
        website: null,
        countiesServed: [],
        demographics: [],
        areasOfFocus: [],
        programs: [],
        certifications: [],
        services: [],
        organizationTypes: [],
        nonprofitOrganization: false,
        acceptingNewClients: false,
        visibleInImpactHQ: true,
        description: null,
        users: [currentUser.id]
      };
    } else {
      this.profilePictureUrl = `${process.env.VUE_APP_API_BASE_URL}${this.profile.profilePicture.url}`;
    }
    
    if (this.profile.services.some(s => s.startsWith('Other~'))) {
      this.profile.otherServices = this.profile.services.find(s => s.startsWith('Other~')).replace('Other~', '');
      this.profile.services = this.profile.services.filter(s => !s.startsWith('Other~'));
    }
  },
  methods: {
    async profilePictureUpdated() {
      if (!this.profilePicture) {
        this.profilePictureUrl = '';
      }
      const base64String = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(this.profile.profilePicture);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
      this.profilePictureUrl = base64String;
    },
    async save() {
      this.sending = true;
      try {
        if (this.profile.otherServices && this.profile.services?.includes('Other (please specify)')) {
          this.profile.services.push(`Other~${this.profile.otherServices}`);
        }
        await Vue.$organizationService.save(this.profile);
        this.$emit('closed');
      } catch(err) {
        console.log(err);
        //Vue.$eventBus.$emit("notifyUser", "Error saving organization");
      } finally {
        this.sending = false;
      }
    }
  }
};
</script>