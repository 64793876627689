import Vue from "vue";
import VueRouter from "vue-router";
import TrainingEvent from '../views/News/SingleTraningEvent.vue'
import Account from '../views/Account.vue'


Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "landing",
    component: () => import("../views/Home/Landing.vue"),
    meta: {
      allowAnonymous: true
    },
  },
  {
    path: "/registrationConfirmation",
    name: "registrationConfirmation",
    component: () => import("../views/RegistrationConfirmation.vue"),
    meta: {
      allowAnonymous: true
    },
  },
  {
    path: "/",
    name: "home",
    component: () => import("../views/Home.vue"),
    meta: {
      allowAnonymous: true
    },
    children: [   
      {
        path: "/auth/login",
        name: "login",
        component: () => import("../components/Login.vue"),
        meta: {
          allowAnonymous: true
        },
      },
      {
        path: '/account',
        name: 'Account',
        component: Account
      },
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("../views/Home/Dashboard.vue"),
      },
      {
        path: "/industry",
        name: "industry",
        component: () => import("../views/News/IndustryNews.vue"),
      },
      {
        path: "/industry/:slug",
        name: "Industry",
        component: () => import("../views/News/SingleIndustryNews.vue"),
      },
      {
        path: "/training-event",
        name: "training-event",
        component: () => import("../views/News/TraningEvent.vue"),
      },
      {
        path: "/training/:slug",
        name: "Training",
        component: TrainingEvent,
      },
      {
        path : "/racial-equity",
        name : "racial-equity",
        component : ()=> import("../views/LearnByTopic/RacialEquity.vue"),
      },
      {
        path : "/technology",
        name : "technology",
        component : ()=> import("../views/LearnByTopic/Technology.vue"),
      },
      {
        path : "/fund-development",
        name : "fund-development",
        component : ()=> import("../views/LearnByTopic/FundDevelopment.vue"),
      },
      {
        path : "/healthcare",
        name : "healthcare-integration",
        component : ()=> import("../views/LearnByTopic/HealthCareIntegration.vue"),
      },
      {
        path : "/grant-depot",
        name : "grant-depot",
        component : ()=> import("../views/SelfGuidedResources/GrantDepot.vue"),
      },
      {
        path: "/grant/:slug",
        name: "Grant",
        component: () => import("../views/SelfGuidedResources/SingleGrant.vue"),
      },
      {
        path : "/capacity-building",
        name : "capacity-building",
        component : ()=> import("../views/OrgSupport/CapacityBuildingSpecialists.vue"),
      },
      {
        path : "/capacity-central",
        name : "capacity-central",
        component : ()=> import("../views/SelfGuidedResources/CapacityCentral.vue"),
      },
      {
        path : "/nonprofit-junction",
        name : "nonprofit-junction",
        component : ()=> import("../views/SelfGuidedResources/NonProfitJunction.vue"),
      },
      {
        path : "/skill-based-volunteers",
        name : "skill-based-volunteers",
        component : ()=> import("../views/OrgSupport/SkillBasedVolunteers.vue"),
      },
      {
        path : "/funder-directory",
        name : "funder-directory",
        component : ()=> import("../views/OrgSupport/FunderDirectory.vue"),
      },
      {
        path: "/new-impact/:slug",
        name: "newOnImpact",
        component: () => import("../views/News/SingleNewImpact.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
      }
    }
    document.getElementById("app").scrollIntoView({ behavior: "smooth" });
  },
});

router.beforeEach(async (to, from, next) => {
  if (Vue.$userService.isLoggedIn()) {
    if (to.meta.allowAnonymous) {
      return next('/dashboard');
    }
    return next();
  } 
  
  if (!to.meta.allowAnonymous) {
    return next(false);
  }

  return next();
});

export default router;
