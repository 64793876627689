export default {
  install: (Vue) => {
    let currentUser = null
    let currentUserPromise = null;

    //public
    Vue.$userService = {
      isLoggedIn() {
        var jwt = Vue.$cookies.get('jwt')
        if (jwt) {
          Vue.axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${jwt}`;
        }
        return !!jwt;
      },
      async current() {
        if (!this.isLoggedIn()) {
          return null;
        }
        var user = currentUser;
        if (!user) {
          var allcoAccessToken = Vue.$cookies.get('allcoAccessToken')
          currentUserPromise = currentUserPromise || Vue.axios.get(process.env.VUE_APP_API_BASE_URL + '/api/users/me', {
            params: {
              allcoAccessToken
            }
          });
          currentUser = (await currentUserPromise).data;
          user = currentUser;
        }
        return user;
      },
      async localLogin(identifier, password) {
        var localLoginInfo = await Vue.axios.post(process.env.VUE_APP_API_BASE_URL + '/api/auth/local', {
          identifier,
          password
        });
        Vue.$cookies.set('jwt', localLoginInfo.data.jwt);
      }
    };
  }
};